<template>
  <v-theme-provider>
    <base-section id="miniweb-feature" :min-height="minHeight">
      <div class="text-center">
        <v-row>
          <v-col>
            <div class="text-h4 text-left mt-10 ml-5">
              {{ $t("bookingfeature.line1") }}
            </div>
            <div class="text-h6 text-left mt-8 ml-5">
              {{ $t("bookingfeature.tagline1") }}
              <ul>
                <li>{{ $t("bookingfeature.li1") }}</li>
                <li>{{ $t("bookingfeature.li2") }}</li>
                <li>{{ $t("bookingfeature.li3") }}</li>
              </ul>
            </div>
            <kdw-btn
              id="accountCreation"
              class="mt-10"
              @click="openConnexion()"
            >
              {{ $t("button.createAccountInfo") }}
            </kdw-btn>
          </v-col>
          <v-col>
            <div class="text-center">
              <v-container>
                <base-img
                  :src="require('@/assets/FeatureBookingExampleCompressed.png')"
                  alt="Booking"
                  class="mx-auto"
                  max-width="700"
                />
              </v-container>
            </div>
          </v-col>
        </v-row>
      </div>
    </base-section>
  </v-theme-provider>
</template>

<script>
import { navigate } from "@/helpers/Navigation";
export default {
  name: "SectionFeatureProBooking",

  data: () => ({}),
  computed: {
    minHeight() {
      const height = this.$vuetify.breakpoint.mdAndUp ? "100vh" : "50vh";

      return `Math.trunc(calc(${height} - ${this.$vuetify.application.top}px) * 0.8)`;
    },
  },
  methods: {
    openConnexion() {
      navigate(this, "PLogin");
    },
  },
};
</script>
